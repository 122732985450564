<template>
  <zee-container :classname="'ml-5'" :showIcon="true" :title="'Program outputs Summary'">
    <div class="zee-output-program-container">
      <table class="zee-output-program-table">
        <thead>
          <tr>
            <th>
              <span class="zee-output-program-table-header">Plan Name</span>
            </th>
            <th>
              <span class="zee-output-program-table-header">Program Name</span>
            </th>
            <th>
              <span class="zee-output-program-table-header">Creation Date</span>
            </th>
            <th>
              <span class="zee-output-program-table-header">Launch Date</span>
            </th>
            <th>
              <span class="zee-output-program-table-header">Total Weeks</span>
            </th>
            <th>
              <span class="zee-output-program-table-header">Camp. Start Date</span>
            </th>
            <th>
              <span class="zee-output-program-table-header">Action</span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(item, index) in programOutputData.columns"
            :key="index"
            valign="top"
          >
            <td>
              <span class="zee-output-program-table-body" :title="item.planName">{{
                item.planName
              }}</span>
            </td>
            <td>
              <span class="zee-output-program-table-body" :title="item.programName">{{
                item.programName
              }}</span>
            </td>
            <td>
              <span class="zee-output-program-table-body" :title="item.creationDate">{{
                item.creationDate
              }}</span>
            </td>
            <td>
              <span
                class="zee-output-program-table-body"
                :title="item.programLaunchDate"
                >{{ item.programLaunchDate }}</span
              >
            </td>
            <td>
              <span
                class="zee-output-program-table-body"
                :title="item.programNumberOfWeeks"
                >{{ item.programNumberOfWeeks }}</span
              >
            </td>
            <td>
              <span class="zee-output-program-table-body" :title="item.campaignStartDate">
                {{ item.campaignStartDate }}</span
              >
            </td>
            <!-- <td>
              <span class="zee-output-program-table-body" :title="item.progress">{{
                item.progress
              }}</span>
            </td>
            <td>
              <span class="zee-output-program-table-body" :title="item.status">{{
                item.status
              }}</span>
            </td> -->
            <td>
              <button
                class="zee-output-program-table-btn"
                style="color: #000"
                @click="viewResultsModal(item)"
              >
                View Result
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="text-right pt-5 d-none">
      <button class="zee-output-program-table-btn" style="background: #6db056">
        Save
      </button>
    </div>

    <program-output-modal
      :modalShow="viewResultsModalShow"
      @closeModal="closeModal"
      :modaldetails="viewResultsDetails"
    />
    <page-loader v-if="isLoading"></page-loader>
  </zee-container>
</template>

<script>
import { Container, ProgramOutputModal } from "../components";
import PageLoader from "@/widgets/PageLoader.vue";

import { zeeTvSolutions } from "../services/zeeTvSolutions";
const zeeSolutions = new zeeTvSolutions();

export default {
  name: "OutputSummary",
  components: {
    "zee-container": Container,
    "program-output-modal": ProgramOutputModal,
    "page-loader": PageLoader,
  },
  data() {
    return {
      viewResultsModalShow: false,
      viewResultsDetails: {},
      programOutputData: {
        columns: [],
      },
      isLoading: false,
    };
  },
  methods: {
    getSummaryTableData() {
      this.isLoading = true;
      let clientId = sessionStorage.getItem("clientId");
      zeeSolutions
        .outputSummaries(clientId)
        .then((res) => {
          this.programOutputData.columns = [];
          this.programOutputData.columns = res;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          alert(err);
        });
    },
    viewResultsModal(item) {
      console.log(item);
      sessionStorage.setItem("run_id", item.runid);
      this.$router.push({
        name: "ZEETV Program Output",
        params: { data: "output-summary" },
      });
      // this.viewResultsModalShow = true;
      // this.viewResultsDetails = item;
    },
    closeModal() {
      this.viewResultsModalShow = false;
    },
  },
  created() {
    this.getSummaryTableData();
  },
};
</script>

<style>
.zee-output-program-container {
  overflow-x: auto;
}

.zee-output-program-container::-webkit-scrollbar {
  display: none;
}

.zee-output-program-table {
  font-size: 16px;
  font-weight: bold;
}

.zee-output-program-table tbody {
  padding-top: 32px;
}

.zee-output-program-table th,
.zee-output-program-table td {
  padding: 8px;
}

.zee-output-program-table-header,
.zee-output-program-table-body,
.zee-output-program-table-btn {
  width: 160px;
  display: inline-block;
  background-color: #313131;
  color: #fff;
  padding: 6px 12px;
  text-align: center;
  font-size: 14px;
  border-radius: 8px;
  border: none;
  text-align: center;
}

.zee-output-program-table-header {
  background-color: #6c757d;
}

.zee-output-program-table-btn {
  background-color: #0dcaf0;
}
</style>
